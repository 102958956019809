@import "assets/styles/style";

@font-face {
  font-family: Montserrat-Regular;
  src: url("/assets/font/Montserrat-Regular.ttf");
}
@font-face {
  font-family: Montserrat-Medium;
  src: url("/assets/font/Montserrat-Medium.ttf");
}

@font-face {
  font-family: login;
  ///src: url("/assets/font/Bokonique-Light.otf");
  src: url("/assets/font/BIG-JOHN.otf");
}

@font-face {
  font-family: signinPass;
  src: url("/assets/font/OpenSans-Regular.ttf");
}

@font-face {
  font-family: navlogo;
  src: url("/assets/font/BalooPaaji-Regular.ttf");
}
