$white-color: #ffffff;
$text-color: #333333;
$red-color: #d94242;
$yellow-color: #f0ad4e;
$grey-background: #efefef;
$dark-grey-background: #e4e7ec;

$text-color-dark: #2f4050;
$text-color-light: #647283;
$border-color: #6472834d;
$border-color-light: #e3f0ff;

// $box-shadow: 0px 12px 50px 2px #42939526;
$box-shadow: 0px 12px 50px 2px #13507c24;

//grid
$grid-breakpoint-xl: 1600px;
$grid-breakpoint-lg: 1280px;
$grid-breakpoint-md: 1024px;
$grid-breakpoint-sm: 768px;
$grid-breakpoint-xs: 360px;
$grid-breakpoint-lg-max: 1599px;
$grid-breakpoint-md-max: 1279px;
$grid-breakpoint-sm-max: 1023px;
$grid-breakpoint-xs-max: 767px;

/** 1600 и выше */
$grid-gutter-xl: 10px;
/** 1280 – 1599 */
$grid-gutter-lg: 10px;
/** 1024 – 1279 */
$grid-gutter-md: 10px;
/** 768 – 1023 */
$grid-gutter-sm: 10px;
/** 360 – 767 */
$grid-gutter-xs: 10px;

$container-padding-xl: 10px;
$container-padding-lg: 10px;
$container-padding-md: 10px;
$container-padding-sm: 40px;
$container-padding-xs: 16px;

$container-width-xl: 1440px + $container-padding-xl * 2;
$container-width-lg: 1120px + $container-padding-lg * 2;
$container-width-md: 896px + $container-padding-md * 2;

// main header size
$main-header-height-base: 76px;
//padding
$container-padding-xl: 30px;

// fontSize
$fontSize8: calc(8px + 0.1vw);
$fontSize10: calc(10px + 0.1vw);
$fontSize12: calc(12px + 0.1vw);
$fontSize14: calc(14px + 0.1vw);
$fontSize16: calc(16px + 0.1vw);
$fontSize18: calc(18px + 0.1vw);
$fontSize20: calc(20px + 0.1vw);
$fontSize22: calc(22px + 0.1vw);
$fontSize24: calc(24px + 0.1vw);
$fontSize26: calc(26px + 0.1vw);
$fontSize28: calc(28px + 0.1vw);
$fontSize30: calc(30px + 0.1vw);
