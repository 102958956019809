/*# sourceURL=_sidebar.scss */
#right-sidebar {
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  top: 60px;
  width: 30vw;
  min-width: 300px;
  max-width: 350px;
  z-index: 6;
  bottom: 0;
  right: 0;
  .sidebar-title {
    background: var(--main-color);
    padding: 5px 30px 5px 15px;
    color: var(--white);
    height: 50px;
  }
}

@media (max-width: 767px) {
  #right-sidebar {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
  }
}
