.ofoodo {
    @font-face {
      font-family: Roboto;
      src: url("/assets/font/Roboto-Light.ttf");
    }
  
    @font-face {
      font-family: OpenSans;
      src: url("/assets/font/OpenSans-Regular.ttf");
    }
  
    --navy: #2e5676;
    --nav-active-color: #062449;
    --nav-bg: #2473d5;
    --nav-text-color: #e6e6e6;
    --nav-second-level: #162635;
  
    --gray: #f3f3f4;
    --light-gray: #d1dade; // Placeholder ,switch borders
    --dark-gray: #dfdfdf; // Placeholder ,switch borders
    --blue: #1c84c6;
    --main-bg: #364654;
    --main-color: #2473d5; // Button colors
  
    --login-registration-nav: #fff;
    --login-bg: #2473d5;
    --login-button-color: #fff;
    --login-input-color: #fff;
    --login-button-text: #2473d5;
  
    --registration-bg: #2473d5;
    --registration-input-color: #fff;
    --registration-button-color: #fff;
    --registration-button-text: #2473d5;
  
    --yellow: #ffc107;
    --red: #ed5565;
    --white: #fff;
    --black: #333;
  
    --text-color: #162635;
    --label-badge-color: #5e5e5e;
    --light-blue: #f3f6fb;
    --bodyhtmlBg: #e6e6e6;
    --border-color: #ddd;
    --ibox-title-bg: #fff;
    --ibox-content-bg: #ffffff;
    --IboxBg: #1b2033;
  
    --sidebar-width: 220px;
    --boxed-width: 1200px;
    --btn-border-radius: 3px;
    --header-font-size: 14px;
    --title-text: 15px;
    --additional-text: 14px;
    --font-family: OpenSans;
    --group-list-title: 15px;
    --group-list-additional: 13px;
  }
  