/*# sourceURL=_landing.scss */
.step-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .input-block {
    margin-top: 25px;
  }
  hr {
    border: 1px solid #e8e8e8;
    margin: 15px 0;
  }
  .bg-btn,
  .bd-btn {
    min-width: 100px;
  }
}
