/*# sourceURL=_landing.scss */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  height: 100%;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: auto;
  scroll-behavior: smooth;
  touch-action: manipulation;
}

body,
html {
  height: 100%;
  overflow: hidden;
  -ms-overflow: hidden;
  -moz-overflow: hidden;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: auto;
  scroll-behavior: smooth;
  touch-action: manipulation;
}

body.full-height-layout #page-wrapper,
body.full-height-layout #wrapper {
  height: 100%;
}

ul {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}
sup {
  color: $red-color;
}
input:focus {
  outline: none;
}

a {
  cursor: pointer;
  color: unset;
}

a:focus,
a:hover {
  text-decoration: none;
}

// ????????????????????????????????
// ????????????????????????????????
// ????????????????????????????????
* {
  font-family: Montserrat-Regular;
}

// ????????????????????????????????
// ????????????????????????????????
// ????????????????????????????????
app-root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: var(--main-color);
  }
}
.available {
  color: var(--main-color);
}
.m-0 {
  margin: 0 !important;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}
.p-0 {
  padding: 0 !important;
}
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-end {
  justify-content: flex-end;
}

.row-reverse {
  flex-direction: row-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-15 {
  gap: 15px;
}

.gap-10 {
  gap: 10px;
}

.titleText {
  font-size: $fontSize16;
  font-weight: 600;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.d__none {
  display: none !important;
}

.relative {
  position: relative;
}

.nowrap {
  white-space: nowrap;
}

.ellipsis {
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold {
  font-weight: 600 !important;
}

.h-100 {
  height: 100%;
}

.icon__size {
  height: 22px;
  width: 22px;
}

.disable {
  pointer-events: none;
  opacity: 0.6;
}

.pointer {
  cursor: pointer;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card__item {
  border: 1px solid $border-color-light;
  border-radius: 8px;
  padding: 10px;

  .remove {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
  }
}

hr {
  border: 1px solid $border-color-light;
  margin: 15px 0;
}

.main-color {
  color: var(--main-color) !important;
}

.red-color {
  color: $red-color;
}

.opacityItem {
  opacity: 0.5;
}
.selectedBorder {
  border: 1px solid var(--main-color) !important;
}

.main-block {
  height: 100%;
  border-radius: 8px;
  padding: 0 20px 20px 20px;
  box-shadow: $box-shadow;
  position: relative;
  min-height: calc(100vh - 136px);
  display: flex;
  flex-direction: column;
}

.scroll-block {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 226px);
  padding-right: 5px;
}

.scroll-block-header {
  position: sticky;
  top: 0;
  padding-bottom: 20px;
  z-index: 1;
  background-color: $white-color;
}
.scroll-block-small {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}
.main-wrapper {
  .bg-btn,
  .bd-btn,
  .grey-btn,
  .cancel-btn {
    min-width: auto !important;
  }
}

button {
  background: none;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.bg-btn,
.bd-btn,
.grey-btn,
.cancel-btn {
  border-radius: 6px;
  height: 36px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  background: none;
  border: 1px solid var(--main-color);
  padding: 10px 15px;
  font-size: $fontSize12;
  font-weight: 500;
  color: $text-color-light;

  &:active {
    color: $text-color;
    background: var(--light-blue);
  }
}

.grey-btn {
  background: $grey-background;
  box-shadow: 0px 4px 8px 0px #00000026;
  padding: 6px 12px;
  border: none;
  font-weight: 500;
}

.bg-btn {
  background-color: var(--main-color);
  color: $white-color;
}

.cancel-btn {
  border: 1px solid transparent;
}

@media (hover: hover) {
  .bg-btn,
  .bd-btn,
  .grey-btn,
  .cancel-btn {
    &:hover {
      color: $text-color-dark;
      background: var(--light-blue);
      box-shadow: inset $box-shadow;
      .icon__img {
        filter: brightness(0) invert(0.5) !important; /* Invert the SVG color in dark mode */
      }
    }
  }

  .cancel-btn:hover {
    border: 1px solid var(--main-color);
  }
}

.input-block {
  margin-top: 15px;
}
.invalid-block {
  .input-field {
    border: 1px solid $red-color;
  }
}
.invalid-field::placeholder {
  color: $red-color !important;
}

.error-message {
  color: $red-color;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  font-size: $fontSize14;
  margin-top: 5px;
}

.input-field,
.input-field-border {
  width: 100%;
  height: 40px;
  border-radius: 7px;
  background-color: $grey-background;
  border: none;
  padding: 5px 10px;
  font-weight: 400;
  font-size: $fontSize14;
  display: flex;
  align-items: center;

  input.transparent {
    width: 100%;
    background-color: transparent !important;
    border: none;
    padding: 0;
  }

  img {
    cursor: pointer;
  }
}

textarea.input-field {
  height: unset;
}

.input-field-border {
  background-color: transparent;
  border: 1px solid $border-color;
}

.input-label {
  font-weight: 500;
  font-size: $fontSize14;
  margin-bottom: 5px;
  color: $text-color-dark;
}

.search__block {
  position: absolute;
  right: 52px;
  top: 14px;
  display: flex;
  align-items: center;

  .search__block-container {
    position: absolute;
    right: 32px;
    border-radius: 6px;
    border: 1px solid var(--main-color);
    background-color: $white-color;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 5px;
    z-index: 1;

    input {
      border: none;
      background-color: transparent;
    }

    button {
      min-width: auto;
      height: 30px;
      background-color: var(--main-color);
      color: $white-color;
      padding: 5px 10px;
    }

    &.hide {
      display: none;
    }
  }

  img {
    cursor: pointer;
  }
}

//checkbox
.styled__checkbox {
  input[type="checkbox"] {
    display: none;
  }

  label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-weight: 550;
  }

  label:before {
    content: "";
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid $border-color;
  }

  input[type="checkbox"]:checked + label:before {
    background: var(--main-color);
    border: none;
  }

  input:checked + label:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

// styled radiobutton
.styled__radio {
  label {
    display: inline-block;
    position: relative;
    outline: none;
    cursor: pointer;
    margin-bottom: 0;
  }

  input[type="radio"] {
    display: none;

    + label {
      &:before {
        content: "";
        background: $white-color;
        border-radius: 100%;
        border: 1px solid var(--main-color);
        display: inline-block;
        width: 18px;
        height: 18px;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
      }
    }

    &:checked {
      + label {
        &:before {
          border: 5px solid var(--main-color);
        }
      }
    }
  }
}

//switch
.switch__block {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
  margin: 0;
  padding: 0;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid var(--main-color);
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: var(--main-color);

    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: var(--main-color);
  }

  input:checked + .slider:before {
    background-color: $white-color;
    transform: translateX(12px);
  }
  &.isOff {
    .slider {
      border: 1px solid var(--red);
    }

    .slider:before {
      background-color: var(--red);
    }

    input:checked + .slider {
      background-color: var(--red);
    }
  }
}

//tabs
.tabs {
  display: flex;

  .tabs__tab button {
    padding: 10px !important;
    cursor: pointer;
    background: none;
    border: none;
    font-size: $fontSize12;
    color: $text-color-light;
    font-weight: 600;
    height: 52px;
    border-bottom: 2px solid $border-color-light;
    transition: all 0.3s;

    &.active {
      color: var(--main-color);
      border-bottom: 2px solid var(--main-color);
    }
  }
}

@media (max-width: 1599px) {
  .tabs {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: auto;
    width: calc(100% - 230px);
  }
}

//modal
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal__overlay-content {
    position: relative;
    background-color: $white-color;
    width: 60vw;
    max-width: 600px;
    border-radius: 15px;
    padding: 20px;
    box-shadow: $box-shadow;

    &.small {
      width: 50vw;
      max-width: 500px;
    }

    &.large {
      width: 80vw;
      max-width: 800px;
    }

    &.extraLarge {
      width: 80vw;
      max-width: 1000px;
    }

    .modal__overlay-close {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    .modal__overlay-title {
      font-size: $fontSize20;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .modal__overlay-body {
      padding-right: 10px;
      max-height: 70vh;
      overflow: auto;
    }

    .modal__overlay-footer {
      margin-top: 20px;
    }
  }

  &.zIndex {
    z-index: 19 !important;
  }
}

//
.image-wrapper {
  position: relative;
  border: 1px solid $border-color-light;
  border-radius: 7px;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .image-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

//
.icon__overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  & > div {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white-color;
    cursor: pointer;

    input {
      display: none;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
}

// uload
.upload__icons {
  .icons-list {
    width: 100%;
    border: 1px solid $border-color;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
    border-radius: 8px;
    max-height: 200px;
    overflow: auto;

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .upload__icon {
    position: relative;

    input {
      opacity: 0;
      width: 24px;
      height: 24px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .uploaded__icon {
    position: absolute;
    right: 10px;
    top: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .loader-bg {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.369);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999998;
  }

  .loader {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin: 0 auto;
    top: 50%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  #loader-1:after,
  #loader-1:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 4px solid transparent;
    border-top-color: var(--main-bg);
  }

  #loader-1:before {
    z-index: 100;
    animation: spin 1s infinite;
  }

  #loader-1:after {
    border: 4px solid var(--white);
  }

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: auto !important;
  padding: 10px;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid $grey-background;
  background-color: $white-color;
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  overflow: auto;
  border-radius: 4px;
  max-height: 60vh;
  &.fullHeight {
    max-height: unset;
  }
  &.top {
    top: -100%;
  }
  li {
    cursor: pointer;
    padding: 5px 0;

    &:active {
      color: var(--main-color);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--main-color);
      }
    }

    span {
      white-space: nowrap;
    }
  }

  &.right {
    left: unset !important;
    right: 0;
  }
  &.center {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.phone__namber {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  position: relative;

  .phone__namber-code {
    min-width: 80px;
    width: 80px;
    cursor: pointer;
  }
}

.header {
  height: 50px;
  width: 100%;
  padding: 5px 0;
  border-bottom: 2px solid $border-color-light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $fontSize16;
  margin-bottom: 20px;

  span {
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }

  button {
    background: none;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header__left-side {
    display: flex;
    align-items: center;
  }

  .header__right-side {
    display: flex;
    align-items: center;
  }
}

//mobile

@media (max-width: $grid-breakpoint-xs-max) {
  .main-block {
    padding: 0 10px 10px 10px;
  }

  .modal__overlay .modal__overlay-content {
    width: 96vw !important;
    max-width: 500px !important;
    padding: 15px;
  }

  .tabs {
    width: 100%;
  }

  .header {
    font-size: $fontSize12;
  }

  .header__mobile {
    min-height: 50px;
    height: auto;
    align-items: normal;
    flex-direction: column-reverse;

    .header__right-side {
      justify-content: flex-end;
      margin-bottom: 5px;
    }
  }

  .search__block {
    right: 42px;
    top: 5px;
  }
}

body[dark="dark"] {
  background-color: #232323;
  color: $white-color;
  .item-selected {
    background: transparent !important;
  }
  .main-block,
  select,
  textarea,
  .grey-btn,
  .scroll-block-header,
  .search__block .search__block-container {
    background-color: #333333 !important;
  }
  input::placeholder {
    color: #aaaaaa;
    opacity: 1;
  }
  hr,
  .image-wrapper {
    border: 1px solid $border-color;
  }
  .modal__overlay {
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
  }
  .header {
    border-bottom: 2px solid $border-color;
  }

  header,
  .input-field,
  table tbody tr,
  .dropdown-menu,
  .modal__overlay-footer {
    background-color: #232323 !important;
  }

  .tab {
    .tab__head {
      border-bottom: 1px solid #ffffff4d !important;
      button {
        color: initial !important;
        &.active {
          border: 1px solid #ffffff4d !important;
          background-color: #333333 !important;
          color: $white-color !important;
          border-bottom: 1px solid #333333 !important;
        }
      }
    }
    .tab__container {
      border: 1px solid #ffffff4d !important;
      border-top: 0px !important;
    }
  }
  .input-label,
  select,
  textarea,
  .bd-btn,
  .cancel-btn,
  .tabs__tab button,
  .grey-btn {
    color: $white-color;
  }
  .input-field-border,
  .time__card,
  .card__item,
  .tree__view-item,
  .styled__checkbox label:before {
    border: 1px solid #ffffff4d;
  }

  .dropdown-menu > .input-field,
  .sticky {
    background-color: #333333 !important;
  }
  .modal__overlay-content {
    border: 1px solid $border-color;
    background-color: #232323;

    .input-field {
      background-color: #333333 !important;
    }
  }
  .priority input {
    color: $text-color-dark !important;
  }
  @media (hover: hover) {
    .tree__view-item:hover,
    .cancel-btn:hover {
      color: $text-color-dark !important;
      background: var(--light-blue);
      .icon__img {
        filter: brightness(0) invert(0.5) !important; /* Invert the SVG color in dark mode */
      }
    }
    .tree__view-item {
      border: 1px solid $border-color;
      .styled__checkbox label:before {
        border: 1px solid $border-color;
      }
    }
  }
  nav ul {
    li > div {
      color: $white-color !important;
    }

    @media (hover: hover) {
      li:not(.active):hover > div {
        color: $text-color-dark !important;
        background: var(--light-blue);
        .icon__img {
          filter: brightness(0) invert(0.5) !important; /* Invert the SVG color in dark mode */
        }
      }
    }
  }
  .icon__img {
    filter: brightness(0) invert(1); /* Invert the SVG color in dark mode */
  }
}

select {
  border: 0;
  outline: 0;
  color: #647283;
  padding-right: 10px !important;
  width: 100%;
  background-color: transparent;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  option {
    color: inherit;
  }
}
